.admin-wrapper {
  @apply w-full !important;
}

.admin-date-picker {
  color: #323232 !important;
  border-color: #dddddd !important;
  @apply w-full text-sm rounded-lg p-1 border-2 border-solid !important;
  @apply transition-colors duration-200 !important;
}

.admin-date-picker:focus {
  border-color: #00aeef !important;
  @apply ring-0 !important;
}
